<template>
  <LiefengContent>
    <template v-slot:title>活动情况统计</template>
    <template v-slot:toolsbarRight>
      <span style="line-height: 32px; margin-right: 4px">活动类型：</span>
      <Select v-model="activeType" style="width: 200px; margin-right: 10px">
        <Option
          v-for="item in activeTypeList"
          :value="item.value"
          :key="item.value"
          >{{ item.label }}</Option
        >
      </Select>
      <span style="line-height: 32px; margin: 0 4px">活动编码：</span>
      <Input
      :maxlength="20"
        v-model.trim="activeCode"
        placeholder="请输入活动编码"
        style="width: 200px; margin-right: 10px"
      ></Input>
      <Button @click="search" icon="ios-search" type="info">查询</Button>
    </template>
    <template v-slot:contentArea>
      <div
        style="
          display: flex;
          align-items: stretch;
          height: 150px;
          justify-content: space-around;
          margin: 20px 0;
        "
      >
        <Card class="cardsty" style="background: #2d8cf0">
          <div>
            <h1>{{ totalData.participatePeople }}</h1>
            <p>参与人数</p>
          </div>
        </Card>
        <Card class="cardsty">
          <div>
            <h1>{{ totalData.followPeople }}</h1>
            <p>关注人数</p>
          </div>
        </Card>
        <Card class="cardsty" style="background: #47cb89">
          <div>
            <h1>{{ totalData.browsePeople }}</h1>
            <p>浏览次数</p>
          </div>
        </Card>
      </div>
      <Card style="width:98%;margin:50px 1% 0 1%;">
      <Row type="flex" justify="space-between">
        <Col span="6" style="text-align:center;font-size:16px;font-weight:bold;" v-for="(item,index) in chartData" :key="index">{{item.columns[1]}} <VePie style="margin-top:-20px;" :data="item" :settings="settingsparam" :legend="{show:false}" ></VePie></Col>
      </Row>
      </Card>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/statisticsactivities')
import LiefengContent from "@/components/LiefengContent";
import VePie from "v-charts/lib/pie.common"
export default {
  data() {
    return {
      //活动类型
      activeType: "",
      activeTypeList: [
        { value: "0", label: "全部" },
        { value: "1", label: "个人活动" },
        { value: "2", label: "团队活动" },
        { value: "3", label: "商品活动" },
        { value: "4", label: "秒杀活动" },
        { value: "5", label: "积分兑换" },
      ],
      //活动编码
      activeCode: "",
      totalData: {},
      //饼图
      chartData: [],
      settingsparam:{radius:80}
    };
  },
  methods: {
    search() {},
    loadTotalData() {
      this.$Message.loading({
        content: "数据加载中...",
        duration: 0,
      });

      this.$get('/testjson/statistics/activitiestotaldata.json',{
        oemCode: parent.vue.oemInfo.oemCode,
      }).then(res => {
        if(res.code == 200 && res.dataList) {
          this.totalData = res.dataList;
          this.loadPublishData();
        }else {
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
        this.$Message.destroy();
      }).catch(err => {
        this.$Message.error({
          background:true,
          content: "获取数据失败"
        })
        this.$Message.destroy()
      })
    },
    loadPublishData(){//读取栏目数
      this.$get('/testjson/statistics/activitiespublishdata.json').then(res => {
        if(res.code == 200 && res.data) {
          this.chartData = res.data;
        }
      })
    }
  },
  created() {
    this.loadTotalData()
  },
  components: {
    LiefengContent,VePie
  },
};
</script>
    
<style scoped lang='less'>
.cardsty {
  min-width: 250px;
  width: 22%;
  background: #2db7f5;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.cardsty p {
  font-size: 16px;
  margin-top: 20px;
}
</style>